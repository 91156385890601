<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar style="height: 76px;"
                title="在线沟通"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <template>
            <div>
                <beautiful-chat
                        :participants="participants"
                        :titleImageUrl="titleImageUrl"
                        :onMessageWasSent="onMessageWasSent"
                        :messageList="messageList"
                        :newMessagesCount="newMessagesCount"
                        :isOpen="isChatOpen"
                        :close="closeChat"
                        :open="openChat"
                        :showEmoji="true"
                        :showFile="true"
                        :showEdition="true"
                        :showDeletion="true"
                        :showTypingIndicator="showTypingIndicator"
                        :showLauncher="true"
                        :showCloseButton="true"
                        :colors="colors"
                        :alwaysScrollToBottom="alwaysScrollToBottom"
                        :disableUserListToggle="false"
                        :messageStyling="messageStyling"
                        @onType="handleOnType"
                        @edit="editMessage" />
            </div>
        </template>
        <RLFooter/>
    </div>
</template>

<script>

    // import {Cell as vcell,Empty,Image as vimg} from 'vant';
    import {getToken} from '@/api/chat';
    // import {getBaseInfo} from '@/api/patient/baseinfo';

    import * as RongIMLib from '@rongcloud/imlib-next'
    // import {getUser} from "@/api/user";
    import {getChatDoctors, saveHistoryMessages, updateChatDoctors} from "@/api/doctor/baseinfo";

    export default {
        name: 'toChat',
        components: {
            // Chat
        },
        data(){
            return {
                patImid:"",
                docid:"",
                im:null,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                participants: [
                    {
                        id: 'me',
                        name: 'Me',
                        imageUrl: 'http://r4z3z4b52.hn-bkt.clouddn.com/patIm.png'
                    },
                    {
                        id: 'doc_'+this.$route.params.userid,
                        name: 'Admin',
                        imageUrl: 'http://r4z3z4b52.hn-bkt.clouddn.com/userIm.png'
                    }
                ], // 对话的所有参与者的列表。' name '是用户名，' id '用于建立消息的作者，' imageUrl '应该是用户头像。
                titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
                messageList: [
                    // { type: 'text', author: 'me', data: { text: 'Say yes!' ,meta:'2021-12-12 12:12:12'} },
                    // { type: 'text', author: 'doc_'+this.$route.params.userid, data: { text: 'No.',meta:'2021-12-12 12:12:13' } },
                    // { type: 'text', author: `user2`, data: { text: 'No.' } }
                ], // // 要显示的消息列表可以动态地分页和调整
                newMessagesCount: 0,
                isChatOpen: true, // 确定聊天窗口应该打开还是关闭
                showTypingIndicator: '',  // 当设置为匹配参与者的值时。它显示特定用户的输入指示
                colors: {
                    header: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    launcher: {
                        bg: '#4e8cff'
                    },
                    messageList: {
                        bg: '#ffffff'
                    },
                    sentMessage: {
                        bg: '#4e8cff',
                        text: '#ffffff'
                    },
                    receivedMessage: {
                        bg: '#eaeaea',
                        text: '#222222'
                    },
                    userInput: {
                        bg: '#f4f7f9',
                        text: '#565867'
                    }
                }, // specifies the color scheme for the component
                alwaysScrollToBottom: true, // 当设置为true时，当有新事件发生时(新消息，用户开始输入…)，总是将聊天滚动到底部。
                messageStyling: true // 启用*bold* /emph/ _underline_等(更多信息请访问github.com/mattezza/msgdown)

            };
        },
        computed:{

        },
        methods:{
            sendMessage (text) {
                if (text.length > 0) {
                    this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
                    this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
                    console.log("发送消息")
                }
            },
            onMessageWasSent (message) {
                // 当用户发送消息时调用
                message.data.meta = this.formatDate((new Date()).valueOf());
                this.messageList = [ ...this.messageList, message ]
                console.log("当用户发送消息时调用")
                console.log(message)
                // var conversation = this.im.Conversation.get({
                //     targetId: this.docid,
                //     type: RongIMLib.CONVERSATION_TYPE.PRIVATE
                // });
                // conversation.send({
                //     messageType: 'RC:TxtMsg', // 填写开发者定义的 messageType
                //     // messageType: 's:person', // 填写开发者定义的 messageType
                //     // content: { // 填写开发者定义的消息内容
                //     //     content: messageContent,
                //     // },
                //     content:message.data.text,
                //     isPersited: true,// 是否存储在服务端,默认为 true
                //     isCounted: true,  // 是否计数. 计数消息接收端接收后未读数加 1，默认为 true
                //     pushContent:'user 发送了一条消息',  // Push 显示内容
                //     pushData: 'Push 通知时附加信息',  // Push 通知时附加信息, 可不填
                //     isStatusMessage: false , // 设置为 true 后 isPersited 和 isCounted 属性失效
                //     disableNotification: false, // 设置为 true 后移动端不会收到 Push 信息和本地通知提醒
                // }).then(function(message){
                //     console.log('发送 s:person 消息成功', message);
                // });
                // 定义消息投送目标会话
                const conversation = { conversationType: RongIMLib.ConversationType.PRIVATE, targetId: this.docid }
                // 实例化待发送消息，RongIMLib.TextMessage 为内置文本型消息
                const messageRc = new RongIMLib.TextMessage({ content: message.data.text })
                // 发送
                RongIMLib.sendMessage(conversation, messageRc).then(res => {
                    console.log('发送消息成功', messageRc);
                    console.log(res);
                })
                // this.sendMessage (message.data.text)
            },
            openChat () {
                // 当用户单击fab按钮打开聊天时调用
                this.isChatOpen = true
                this.newMessagesCount = 0
            },
            closeChat () {
                // // 当用户单击按钮关闭聊天时调用
                this.isChatOpen = false
            },
            handleScrollToTop () {

                // 当用户将消息列表滚动到顶部时调用
// 利用分页来加载另一个消息页面

            },
            handleOnType () {
                console.log('Emit typing event')
            },
            editMessage(message){
                const m = this.messageList.find(m=>m.id === message.id);
                m.isEdited = true;
                m.data.text = message.data.text;
            },
            listenNewMessage(messages){
              console.log("新消息")
                for(let message of messages.messages){
                    // console.log(message);
                    // const message = event.message;

                    if(message.messageType.indexOf("RC")!=-1){
                        // console.log("接收新消息")
                        // console.log(messages)
                        console.log("接收到新消息")
                        console.log(message)
                        // console.log(this.docid)

                        let newMessage = {
                            author: "",
                            type: "",
                            data: {
                                text: "",
                                meta:""
                            }
                        }
                        // this.docid = message.senderUserId

                        if(message.senderUserId.indexOf("doctor_")==-1){
                            // newMessage.author='doctor_'+message.senderUserId
                            newMessage.author='patient_'+message.senderUserId
                        }else{
                            newMessage.author=message.senderUserId

                        }
                        newMessage.data.text=message.content.content
                        // newMessage.data.meta=message.sentTime
                        newMessage.data.meta=this.formatDate(message.sentTime)

                        // if(message.messageType == "RC:TxtMsg"){
                        if(message.messageType == "RC:TxtMsg"){
                            newMessage.type = "text"
                        }
                        console.log(newMessage)
                        this.messageList = [ ...this.messageList, newMessage ]
                    }
                }
            },
            formatDate(value){
                if (value == null) {
                    return '';
                } else {
                    let date = new Date(value);
                    let y = date.getFullYear();// 年
                    let MM = date.getMonth() + 1;// 月
                    MM = MM < 10 ? ('0' + MM) : MM;
                    let d = date.getDate();// 日
                    d = d < 10 ? ('0' + d) : d;
                    let h = date.getHours();// 时
                    h = h < 10 ? ('0' + h) : h;
                    let m = date.getMinutes();// 分
                    m = m < 10 ? ('0' + m) : m;
                    let s = date.getSeconds();// 秒
                    s = s < 10 ? ('0' + s) : s;
                    var timer = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
                    return timer //转换结果为 2019-05-15 15:30:24
                }
            },
            loadHistoryMessage(messages){
                for(let i =messages.length -1;i>=0;i--){
                // for(let message of messages){
                  let message = messages[i]
                  // console.log(message.content.content)

                    if(message.messageType.indexOf("RC")!=-1){
                        let newMessage = {
                            author: "",
                            type: "",
                            data: {
                                text: ""
                            }
                        }
                        // this.docid = message.senderUserId
                        if(message.content.content!=''&&typeof(message.content.content)=='string'){
                            if(message.senderUserId.indexOf("doctor_")!=-1){
                              newMessage.author='me'

                            }else if(message.senderUserId.indexOf("patient_")!=-1){
                              newMessage.author=message.senderUserId

                            }else{
                                newMessage.author=message.senderUserId
                            }
                            newMessage.data.text=message.content.content
                            newMessage.data.meta=this.formatDate(message.sentTime)
                            // if(message.messageType == "RC:TxtMsg"){
                            if(message.messageType == "RC:TxtMsg"){
                                newMessage.type = "text"
                            }
                            // this.messageList.splice(0, 0, newMessage);
                            this.messageList = [ newMessage, ...this.messageList ]

                        }
                    }
                }
              // console.log(this.messageList)

            },
          getMessages(token,patientDoctors){
            return new Promise((resolve) => {
              RongIMLib.connect(token).then(async (res) => {
                if (res.code === 0) {
                  console.log('链接成功, 链接用户 id 为: ', res.data.userId);
                  this.patImid = res.data.userId

                  if (patientDoctors.length > 0) {
                    for (const patient_doctor of patientDoctors) {
                      const conversation = {
                        conversationType: RongIMLib.ConversationType.PRIVATE,
                        // targetId: this.docid
                        targetId: 'patient_'+patient_doctor.patient_id
                      }
                      // 从当前时间开始向前查询
                      // const option = {
                      //   timestamp: 0,
                      //   count: 3,
                      //   order: 0
                      // }
                      let messagesList = []
                      const result = this.getRongHistoryMessages(messagesList, conversation, 0, 20, 0)
                      console.log(result)
                    }

                  }
                  resolve(res.data.userId + "同步完成")

                }
              })

            });
          },
          getRongHistoryMessages(ronguserid,messagesList,conversation,timestamp,count,order){
            return new Promise((resolve) => {
              const option = {
                timestamp: timestamp,
                count: count,
                order: order
              }
              RongIMLib.getHistoryMessages(conversation,option).then(async res => {
                if (res.code === 0) {
                  // console.log(res.data.list)
                  // console.log(res.data.hasMore)
                  // messagesList = [...messagesList,...res.data.list]
                  messagesList.unshift(...res.data.list);
                  // console.log(messagesList)
                  // this.loadHistoryMessage(res.data.list)
                  if (res.data.hasMore == true) {
                    let time_new = res.data.list[0].sentTime - 1
                    messagesList = await this.getRongHistoryMessages(ronguserid, messagesList, conversation, time_new, count, order)
                    resolve(messagesList)

                  } else {
                    console.log("加载His")
                    console.log(messagesList)
                    if (messagesList.length > 0) {
                      saveHistoryMessages({loginUserId: ronguserid}, {messages: JSON.stringify(messagesList)}).then((response) => {
                        console.log(response)
                        resolve(messagesList)
                      })

                    } else {
                      resolve(messagesList)

                    }
                  }
                } else {
                  resolve(messagesList)
                  console.log(res.code, res.msg)
                }
              })
            });

          },
          async eachMessage(ronguserid,patientDoctors){
              // let i =0;
              let sum = 0;
            for (const patient_doctor of patientDoctors) {
              const conversation = {
                conversationType: RongIMLib.ConversationType.PRIVATE,
                // targetId: this.docid
                targetId: 'patient_'+patient_doctor.patient_id
              }
              // 从当前时间开始向前查询
              // const option = {
              //   timestamp: 0,
              //   count: 3,
              //   order: 0
              // }
              let messagesList = []
              const result = await this.getRongHistoryMessages(ronguserid,messagesList, conversation, 0, 20, 0)
              console.log(result)
              sum += result.length
              // i++;
            }
            return sum;
          },
          getDoctors(){
              getChatDoctors().then((res) => {
                console.info(res);
                //开发环境
                // this.im = RongIMLib.init({ appkey: 'pkfcgjstpop98' });
                //生产环境
                this.im = RongIMLib.init({ appkey: 'c9kqb3rdcocvj' });
                //doctor_1 admin
                // let token = "kCae8xuT99U2J24m1yWjBpocobUyhFqEEn+aS+JYqY0=@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com"
                //patient_3076
                // let token = "YyqLxLSyfygH2lUA3VPIbS2JGz3KIWA/Im6tatDRi3Fm5bidJ+ArVQ==@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com"
                // this.im.connect({ token: 'P1Oy5mHdTphdHtbR6lVbTTNsz5ZfxBYHz+I1rg5ekDcnjbe6Q3tx6i2JGz3KIWA/qF8QrGoHQ1jKWidklJVwyQ==@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com' }).then(user => {

                if (res.data.length > 0) {
                  // for (let i = 0;i<res.data.length ; i++) {
                  //   let token = res.data[i].token
                  //   let patientDoctors = res.data[i].patient_doctors
                  //   // console.log(obj)
                  //   // const result = await this.getMessages(token,patientDoctors);
                  //   // let token = res.data[0].token
                  //   // let patientDoctors = res.data[0].patient_doctors
                  //   // console.log(obj)
                  //   // const result = await this.getMessages(token,patientDoctors);
                  //   // if(i > 0){
                  //   //   RongIMLib.disconnect().then(() => {
                  //   //     console.log('成功断开')
                  //   //   })
                  //   // }
                  //
                  //   // console.log(result)
                  // }
                  let token = res.data[0].token
                  let patientDoctors = res.data[0].patient_doctors
                  RongIMLib.connect(token).then((res) => {
                    if (res.code === 0) {
                      console.log('链接成功, 链接用户 id 为: ', res.data.userId);
                      this.patImid = res.data.userId

                      if (patientDoctors.length > 0) {

                        this.eachMessage(res.data.userId,patientDoctors).then(response =>{
                          console.log(response)
                          if(response == 0){
                            updateChatDoctors({chatuserid:res.data.userId}).then((response) =>{
                              console.log(response)
                            })
                          }
                        })
                      }else{
                        console.log("没有关联患者")
                        updateChatDoctors({chatuserid:res.data.userId}).then((response) =>{
                          console.log(response)
                        })
                      }
                    }
                  })
                }
              });
            },
            initBaseInfo(){
              this.im = RongIMLib.init({ appkey: 'pkfcgjstpop98' });
              //doctor_1 admin
              let token = "kCae8xuT99U2J24m1yWjBpocobUyhFqEEn+aS+JYqY0=@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com"
              //patient_3076
              // let token = "YyqLxLSyfygH2lUA3VPIbS2JGz3KIWA/Im6tatDRi3Fm5bidJ+ArVQ==@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com"
              // this.im.connect({ token: 'P1Oy5mHdTphdHtbR6lVbTTNsz5ZfxBYHz+I1rg5ekDcnjbe6Q3tx6i2JGz3KIWA/qF8QrGoHQ1jKWidklJVwyQ==@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com' }).then(user => {
              RongIMLib.connect(token).then((res) => {
                if (res.code === 0) {
                  console.log('链接成功, 链接用户 id 为: ', res.data.userId);
                  this.patImid = res.data.userId
                  const conversation = {
                      conversationType: RongIMLib.ConversationType.PRIVATE,
                      // targetId: this.docid
                      targetId: 'patient_3076'
                  }
                  // 从当前时间开始向前查询
                  // const option = {
                  //   timestamp: 0,
                  //   count: 3,
                  //   order: 0
                  // }
                  let messagesList = []
                  this.getRongHistoryMessages(messagesList,conversation,0,20,0)
                  // RongIMLib.getHistoryMessages(conversation,option).then(res => {
                  //     if (res.code === 0) {
                  //         console.log(res.data.list)
                  //         console.log(res.data.hasMore)
                  //         this.loadHistoryMessage(res.data.list)
                  //     } else {
                  //         console.log(res.code, res.msg)
                  //     }
                  // })
                  // const conversation2 = {
                  //     conversationType: RongIMLib.ConversationType.PRIVATE,
                  //     targetId: 'doctor_1'
                  // }
                  //
                  // RongIMLib.getHistoryMessages(conversation2).then(res => {
                  //     if (res.code === 0) {
                  //         console.log(res.data.list)
                  //         console.log(res.data.hasMore)
                  //         this.loadHistoryMessage(res.data.list)
                  //     } else {
                  //         console.log(res.code, res.msg)
                  //     }
                  // })
                }
              })

              const Events = RongIMLib.Events
              RongIMLib.addEventListener(Events.MESSAGES, this.listenNewMessage)
            },

            getRongHistoryMessages2(messagesList,conversation,timestamp,count,order){
              const option = {
                timestamp: timestamp,
                count: count,
                order: order
              }
              RongIMLib.getHistoryMessages(conversation,option).then(res => {
                if (res.code === 0) {
                  // console.log(res.data.list)
                  // console.log(res.data.hasMore)
                  // messagesList = [...messagesList,...res.data.list]
                  messagesList.unshift(...res.data.list);
                  // console.log(messagesList)
                  // this.loadHistoryMessage(res.data.list)
                  if(res.data.hasMore == true){
                    let time_new = res.data.list[0].sentTime -1
                    messagesList = this.getRongHistoryMessages(messagesList,conversation,time_new,count,order)
                  }else{
                    console.log("加载His")
                    console.log(messagesList)
                    this.loadHistoryMessage(messagesList)
                  }
                } else {
                  console.log(res.code, res.msg)
                }
              })
              // console.log("messagesList-->")
              // console.log(messagesList)

              return messagesList;
            },
            initRongCloud(){
                var token="";

                // getBaseInfo({}).then((response) => {
                //   console.info("response1");
                //   console.info(response);
                //   console.info(this.$store.state.user);
                //
                // });

                getToken({pid:this.$store.state.user.pid}).then((response2) => {
                    token = response2.data.token
                    // console.log(token)
                    if(token){
                        this.im = RongIMLib.init({ appkey: 'bmdehs6pbe8qs' });
                        // this.im.connect({ token: 'P1Oy5mHdTphdHtbR6lVbTTNsz5ZfxBYHz+I1rg5ekDcnjbe6Q3tx6i2JGz3KIWA/qF8QrGoHQ1jKWidklJVwyQ==@oxqg.cn.rongnav.com;oxqg.cn.rongcfg.com' }).then(user => {
                        RongIMLib.connect(token).then((res) => {
                            if (res.code === 0) {
                                console.log('链接成功, 链接用户 id 为: ', res.data.userId);
                                this.patImid = res.data.userId
                                // console.log(res.data.userId)
                                // const conversation = {
                                //     conversationType: RongIMLib.ConversationType.PRIVATE,
                                //     targetId: this.docid
                                // }
                                //
                                // RongIMLib.getHistoryMessages(conversation).then(res => {
                                //     if (res.code === 0) {
                                //         console.log(res.data.list)
                                //         console.log(res.data.hasMore)
                                //         this.loadHistoryMessage(res.data.list)
                                //     } else {
                                //         console.log(res.code, res.msg)
                                //     }
                                // })
                                // const conversation2 = {
                                //     conversationType: RongIMLib.ConversationType.PRIVATE,
                                //     targetId: this.patImid
                                // }
                                //
                                // RongIMLib.getHistoryMessages(conversation2).then(res => {
                                //     if (res.code === 0) {
                                //         console.log(res.data.list)
                                //         console.log(res.data.hasMore)
                                //         this.loadHistoryMessage(res.data.list)
                                //     } else {
                                //         console.log(res.code, res.msg)
                                //     }
                                // })
                            }
                        })

                        const Events = RongIMLib.Events
                        RongIMLib.addEventListener(Events.MESSAGES, this.listenNewMessage)



                        // this.im.connect({ token: token }).then(user => {
                        //     console.log('链接成功, 链接用户 id 为: ', user.id);
                        //     this.patImid = user.id
                        // }).catch(error => {
                        //     console.log('链接失败: ', error.code, error.msg);
                        // });
                        // // let IMUI = this.$refs.IMUI;
                        // let patImid = this.user.id
                        // console.log(this.docid)
                        // 添加事件监听
                        // this.im.watch({
                        //     // 监听会话列表变更事件, 触发时机：会话状态变化（置顶、免打扰）、会话未读数变化（未读数增加、未读数清空）、会话 @ 信息、会话最后一条消息变化
                        //     // conversation :function(event) {
                        //     //     // 假定存在 getExistedConversationList 方法，以获取当前已存在的会话列表数据
                        //     //     // const conversationList = getExistedConversationList()
                        //     //     const conversationList = null
                        //     //     // 发生变更的会话列表
                        //     //     const updatedConversationList = event.updatedConversationList;
                        //     //     // 通过 im.Conversation.merge 计算最新的会话列表
                        //     //     const latestConversationList = this.im.Conversation.merge({ conversationList, updatedConversationList })
                        //     //     console.log(latestConversationList)
                        //     // },
                        //     // 监听消息通知
                        //     message: (event)=> {
                        //         // 新接收到的消息内容
                        //         const message = event.message;
                        //
                        //         if(message.messageType.indexOf("RC")!=-1){
                        //             console.log("接收到新消息")
                        //             console.log(message)
                        //             console.log(this.docid)
                        //
                        //             let newMessage = {
                        //                 author: "",
                        //                 type: "",
                        //                 data: {
                        //                     text: ""
                        //                 }
                        //             }
                        //             // this.docid = message.senderUserId
                        //
                        //             if(message.senderUserId.indexOf("doc_")==-1){
                        //                 newMessage.author='doc_'+message.senderUserId
                        //             }else{
                        //                 newMessage.author=message.senderUserId
                        //
                        //             }
                        //             newMessage.data.text=message.content.content
                        //             if(message.messageType == "RC:TxtMsg"){
                        //                 newMessage.type = "text"
                        //             }
                        //             console.log(newMessage)
                        //             this.messageList = [ ...this.messageList, newMessage ]
                        //         }
                        //
                        //     },
                        //     // 监听 IM 连接状态变化
                        //     status:function (event) {
                        //         console.log('connection status:', event.status);
                        //     },
                        //     // 监听聊天室 KV 数据变更，进入、退出聊天室，销毁聊天室
                        //     chatroom:function  (event) {
                        //         /**
                        //          * 聊天室 KV 存储数据更新
                        //          * @example
                        //          * [
                        //          *  {
                        //          *    "key": "name",
                        //          *    "value": "我是小融融",
                        //          *    "timestamp": 1597591258338,
                        //          *    "chatroomId": "z002",
                        //          *    "type": 1 // 1: 更新（ 含:修改和新增 ）、2: 删除
                        //          *  },
                        //          * ]
                        //          */
                        //         const updatedEntries = event.updatedEntries
                        //         console.log(updatedEntries)
                        //     },
                        //     expansion :function (event) {
                        //         /**
                        //          * 更新的消息扩展数据
                        //          * @example {
                        //          *    expansion: { key: 'value' },      // 设置或更新的扩展值
                        //          *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新了扩展的消息 uid
                        //          * }
                        //          */
                        //         const updatedExpansion = event.updatedExpansion;
                        //         /**
                        //          * 删除的消息扩展数据
                        //          * @example {
                        //          *    deletedKeys: ['key1', 'key2'],    // 删除的扩展值
                        //          *    messageUId: 'URIT-URIT-ODMF-DURR' // 删除了扩展的消息 uid
                        //          * }
                        //          */
                        //         const deletedExpansion = event.deletedExpansion;
                        //         console.log(updatedExpansion)
                        //         console.log(deletedExpansion)
                        //
                        //     },
                        //     // 监听离线消息拉取完成
                        //     pullFinished:function  () {
                        //         console.log('拉取离线消息完成')
                        //     },
                        //     // 用于屏蔽的消息监听
                        //     messageBlocked:function  (event) {
                        //         const messageId = event.blockedMessageUId
                        //         const conversationType = event.conversationType
                        //         const targetId = event.targetId
                        //         const blockType = event.blockType
                        //         console.log(messageId)
                        //         console.log(conversationType)
                        //         console.log(targetId)
                        //         console.log(blockType)
                        //
                        //     },
                        //     // 该回调不会给当前操作设备回调，只会给其他的多端设备回调
                        //     // 当用户在其它端添加移除更新标签时会触发此监听器，用于多端之间的信息同步
                        //     tag:function (){
                        //         console.log('标签发生变化')
                        //     }
                        // });
                    }
                });
            }
        }
        ,
        created() {
          this.getDoctors()

          //   console.log("userid-->"+this.$route.params.userid)
          //   this.docid ='doc_'+this.$route.params.userid
          // getUser().then((res) => {
          //   console.info(res.data);
          //
          //   if(res.data){
          //     this.$store.commit('updateOpenid',res.data.openid);
          //     this.$store.commit('updatePid',res.data.id);
          //     this.$store.commit('updateBaseInfo',res.data);
          //     if(res.data.name !=''){
          //       this.substatus = true
          //     }
          //     this.user.id=res.data.id;
          //     this.user.name=res.data.name;
          //     this.user.birthday=res.data.birthday;
          //     // this.user.idCard=response.data.idCard;
          //     this.user.telephone=res.data.telephone;
          //     this.user.gender=res.data.gender+'';
          //     this.user.address=res.data.address;
          //     this.user.detail_address=res.data.detail_address;
          //     // this.initBaseInfo();
          //     this.user_empty = false
          //
          //     // this.initBaseInfo();
          //
          //   }
          // });

        },
        mounted() {
          // getChatDoctors().then((res) => {
          //   console.info(res);
          // });
            // this.initBaseInfo();
        },
    }
</script>