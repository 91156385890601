import request from '@/utils/request'
// 查询绑定医生列表
export function getDoctors(params) {
    params.dataType='noKey';
    return request({
        url: '/chat/getUsers',
        method: 'post',
        params:params
      })
  }
export function getToken(params) {
    params.dataType='noKey';
    return request({
        url: '/chat/getToken2',
        method: 'post',
        params:params,
      })
  }


  //保存crf
// export function saveData(params,data){
//     params.dataType='noKey';
//     return request({
//         url: '/followup/savecrf',
//         method: 'post',
//         params:params,
//         data:data
//     })
// }

//   // 查询问卷结构
// export function getquestus(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/que',
//         method: 'post',
//         params:params
//       })
//   }
//
//
//   // 查询问卷数据
// export function getqueDatas(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/datas',
//         method: 'post',
//         params:params
//       })
//   }
//
//   export function saveDatas(params,data){
//     params.dataType='noKey';
//     return request({
//         url: '/ques/saveDatas',
//         method: 'post',
//         params:params,
//         data:data
//       })
//   }
//
//
//
//   export function pushques(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/push',
//         method: 'post',
//         params:params
//       })
//   }